<template>
    <div>
        <canvas id="c" />
        <h4 id="status" class="error" />
        <div class="container-fluid page-dashboard">
            <div class="row">
                <div class="col-xl-4">
                    <WorldMap :showCompare="true" />
                </div>

                <div class="col-xl-8">
                    <ServerInfo />
                    <iframe id="graph_composer" src="/graph_composer/index.html"></iframe>
                </div>
            </div>

            <div class="frontpage-info-footer"></div>
        </div>
    </div>
</template>

<script>
    import WorldMap from '../components/WorldMap.vue';
    import ServerInfo from '../components/ServerInfo.vue';

    export default {
        components: {
            WorldMap,
            ServerInfo
        },
        mounted() {
        }
    }
</script>
