
import { defineComponent } from "vue";
import { transform, Tag } from 'text-mesh-transformer';

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    stripTags: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    processedText() {
        // Convert the non standard TMP color tags to regular TMP color tags
        let str = this.text.replace(/<#([a-fA-F0-9]+)>/g, '<color=#$1>');     
        
        // Strip CDATA from the string
        str = str.replace(/<!\[CDATA\[(.*?)\]\]>/g, '$1');

        // Replace TMP tags and process the text
        return transform(str, (openTag, content) => {

            // If strip tags is set to true or theres no content to parse
            // just return the raw text
            if (this.stripTags) {
                return content;
            }

            // If the tag is wrapped by a no parse tag, return the raw content
            if (openTag.isWrappedBy(Tag.NoParse)) {
                return content;
            }
            
            // Parse the incoming TMP tag and adjust it for HTML use
            switch (openTag.type) {
                case Tag.Align:
                    return `<div style="text-align: ${openTag.attributes.value}">${content}</div>`;
                case Tag.Alpha:
                    return `<span style="font-family: 'Alpha'">${content}</span>`;
                case Tag.Color:
                    return `<span class="colored-text" style="color: ${openTag.attributes.value}">${content}</span>`;
                case Tag.Bold:
                    return `<b>${content}</b>`;
                case Tag.Italic:
                    return `<i>${content}</i>`;
                case Tag.CSpace:
                    return `<span style="letter-spacing: ${openTag.attributes.value}px">${content}</span>`;
                case Tag.Font:
                    return `<span style="font-family: ${openTag.attributes.value}">${content}</span>`;
                case Tag.Indent:
                    return `<div style="text-indent: ${openTag.attributes.value}px">${content}</div>`;
                case Tag.LineHeight:
                    return `<span style="line-height: ${openTag.attributes.value}">${content}</span>`;
                case Tag.LineIndent:
                    return `<div style="text-indent: ${openTag.attributes.value}px">${content}</div>`;
                case Tag.Link:
                    return `<a class="tmp-link" href="#" data-tmp=${openTag.attributes.value}>${content}</a>`;
                case Tag.Lowercase:
                    return `<span style="text-transform: lowercase">${content}</span>`;
                case Tag.Uppercase:
                    return `<span style="text-transform: uppercase">${content}</span>`;
                case Tag.Smallcaps:
                    return `<span style="font-variant: small-caps">${content}</span>`;
                case Tag.Margin:
                    return `<div style="margin: ${openTag.attributes.value}px">${content}</div>`;
                case Tag.Mark:
                    return `<mark>${content}</mark>`;
                case Tag.MSpace:
                    return `<span style="margin-right: ${openTag.attributes.value}px">${content}</span>`;
                case Tag.NoBR:
                    return content; // Do not parse this tag, return raw content
                case Tag.Page:
                    return `<div class="page" id="${openTag.attributes.value}">${content}</div>`;
                case Tag.Pos:
                    return `<span style="position: ${openTag.attributes.value}">${content}</span>`;
                case Tag.Space:
                    return `<span style="white-space: ${openTag.attributes.value}">${content}</span>`;
                case Tag.Strikethrough:
                    return `<s>${content}</s>`;
                case Tag.Underline:
                    return `<u>${content}</u>`;
                case Tag.Style:
                    return `<span class=${openTag.attributes.value}>${content}</span>`;
                case Tag.Sub:
                    return `<sub>${content}</sub>`;
                case Tag.Sup:
                    return `<sup>${content}</sup>`;
                case Tag.VOffset:
                    return `<span style="vertical-align: ${openTag.attributes.value}">${content}</span>`;
                case Tag.Width:
                    return `<span style="width: ${openTag.attributes.value}px">${content}</span>`;
                default:
                    return content; // Return raw content for unknown tags
            }
        });
    },
  },
  methods: {
    handleClick(event) {
        const clickedElement = event.target;
        const parentElement = clickedElement.parentElement;

        if (parentElement.classList.contains('tmp-link')) {

            // Construct our event data
            const tmpData = parentElement.getAttribute('data-tmp');
            const tmpDataParts = tmpData.split(',');
            const tmpLinkMethod = tmpDataParts[0];
            const tmpLinkParams = tmpDataParts.slice(1);

            const eventData = {
                tmpData: tmpData,
                tmpLinkMethod: tmpLinkMethod,
                tmpLinkParams: tmpLinkParams
            };

            // Emit an event to our parent component/page
            this.$emit('tmp-link-click', eventData);
        }
    }
  }
});
