// plugin to provide info on if WebGL is available
import { Plugin, App } from 'vue'

function isWebGLAvailable(): boolean {
    try {
        const canvas = document.createElement('canvas');
        return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
    } catch (e) {
        return false;
    }
}

const WebGLPlugin: Plugin = {
    install: (app: App, options: any) => {
        app.config.globalProperties.WebGLIsAvailable = isWebGLAvailable();

        app.provide('webgl', options)
    }
};

export default WebGLPlugin;
