<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <h1>Profiling results</h1>

                <ul id="profiling-results-data">
                    <li v-for="(res, i) in store.profiling" :key="`profiling-${i}`">
                        <a :href="getFullResultUrl(res.Name)" target="_blank">
                            {{ res.Name }} ({{ res.Size }} bytes)
                        </a>
                        Created at: {{ formatDate(res.CreatedAt) }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import { useMainStore } from '../stores';

    export default defineComponent({
        setup() {
            const store = useMainStore();

            return { store };
        },
        mounted() {
            this.store.fetchProfilingResults();
        },
        methods: {
            getFullResultUrl(name) {

                const encodedName = encodeURIComponent(name);
                var url = new URL(`/api/v1/profiling-results/${encodedName}`, window.serverUrl);
                url.searchParams.append("authtoken", this.store.authtoken || "");
                url.searchParams.append("authtokentype", this.store.authtokentype || "");

                return url.toString();
            },
            formatDate(date) {
                return new Date(date).toLocaleString();
            }
        }
    });
</script>
