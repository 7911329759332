import { nextTick } from 'vue'
import { createI18n, I18n } from 'vue-i18n'
import enUS from '../locales/en-US.json'

// Type-define 'en-US' as the master schema for the resource
type LocalizationSchema = typeof enUS;

export const SUPPORT_LOCALES = [
    'cs', 'da', 'de', 'el', 'en-US',
    'es', 'es-EN', 'fr', 'hu', 'is',
    'it', 'ja', 'ko', 'nl', 'no', 'pl',
    'pt-BR', 'pt-PT', 'ro', 'ru', 'sv-SE',
    'uk', 'zh-CN'
];

export function getLocale(i18n : I18n) : string {
    return i18n.global.locale.toString();
}

export function setupI18n() : I18n {
    const options = {
        locale: 'en-US',
        fallbackLocale: 'en-US',
        messages: {
            'en-US': enUS
        }
    };

    const i18n = createI18n<[LocalizationSchema]>(options);
    setI18nLanguage(i18n, options.locale);
    return i18n;
}

export function setI18nLanguage(i18n : I18n, locale: string) {
    i18n.global.locale = locale;
    document!.querySelector('html')!.setAttribute('lang', locale);
}

export async function loadLocaleMessages(i18n : I18n, locale: string) : Promise<void> {
    // load locale messages with dynamic import
    const messages = await import(`../locales/${locale}.json`);

    // set locale and locale message
    i18n.global.setLocaleMessage(locale, messages.default);
    return nextTick();
}
