
    import { defineComponent } from 'vue';
    import { default as axios, AxiosError } from 'axios';
    
    export default defineComponent({
        components: {
        },
        methods: {
            async generateTestData() {
                try {
                    await axios.post(`elections/generatetestdata`);
                    this.$swal('Success', 'generated data', 'success');
                }
                catch (e) {
                    if (e instanceof AxiosError) {
                        this.$swal('Oops..', e.response?.data.Message, 'error');
                    } else {
                        this.$swal('Oops..', (<any>e).toJSON(), 'error');
                    }
                }
            },
            async finishElection() {
                try {
                    await axios.post(`elections/finishelection`);
                    this.$swal('Success', 'generated data', 'success');
                }
                catch (e) {
                    if (e instanceof AxiosError) {
                        this.$swal('Oops..', e.response?.data.Message, 'error');
                    } else {
                        this.$swal('Oops..', (<any>e).toJSON(), 'error');
                    }
                }
            }
        }
    });
