
    import { defineComponent } from "vue";
    import { definitions } from "@/types/EcoWebServer";

    type RunoffVoteV1 = definitions["Eco.WebServer.DataTransferObjects.V1.RunoffVoteV1"];

    export default defineComponent({
        name: 'AllVotesModal',
        inheritAttrs: false,
        props: {
            election: {
                type: Object,
                required: true,
            },
            votes: {
                type: Object,
                required: true
            }
        },
        methods: {
            // Returns an array of rank values matching the user's vote choices
            getUserVotes(vote: RunoffVoteV1): Array<number> {
                let choices: Array<number> = [];
                if (this.election && this.votes) {
                    for (let c = 0; c < this.election.Choices.length; ++c) {
                        const option = this.election.Choices[c];
                        let rankIndex = vote.RankedVotes!.indexOf(option.Id);
                        if (this.election.BooleanElection) {
                            choices.push(rankIndex == 0 ? 1 : -1);
                        } else {
                            choices.push(rankIndex > -1 ? rankIndex + 1 : rankIndex);
                        }
                    }
                }
                return choices;
            }
        }
    });
