<template>
    <div>
        <canvas id="c"></canvas>
        <div class="container-fluid page-dashboard">
            <div class="row">
                <div class="map-wrapper-1">
                    <WorldMap />
                </div>

                <div class="map-wrapper-1">
                    <!--<WorldMap/>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import WorldMap from '../components/WorldMap.vue'

    export default {
        components: {
            WorldMap
        }
    }
</script>
