
	//import 'jquery';
    //import 'bootstrap';
    import { defineComponent } from 'vue';
    import axios from 'axios';
    import { useMainStore } from './stores';
	import Sidebar from './components/Sidebar.vue';


	export default defineComponent({
		name: 'App',
		components: {
			Sidebar
		},
		setup() {
			const store = useMainStore();

			return { store };
        },
		beforeCreate() {
			window.serverUrl = process.env.NODE_ENV == 'development' ? 'http://localhost:3001' : '';
			axios.defaults.baseURL = window.serverUrl + "/api/v1";
            axios.interceptors.response.use(response => {
				this.$Progress.finish();

                var lang = response.headers["content-language"];
				if (lang) {
					localStorage.setItem('lang', lang);
                }
                return response;
            }, (error) => {
				this.$Progress.fail();
                return Promise.reject(error);
			});
		},
		mounted() {
			this.store.auth();
		}
	});
