
import 'bootstrap';
import { defineComponent } from 'vue';
import { default as axios, AxiosError } from 'axios';
import Highcharts from 'highcharts';
import { VueDraggableNext } from 'vue-draggable-next'
import { decode } from '@/helpers/data';
import { sec2time, sec2worldTime } from '@/helpers/time';
import 'bootstrap';

import { useMainStore } from '@/stores';
import { definitions } from "@/types/EcoWebServer";

import WorldMap from '@/components/WorldMap.vue';
import TMPLabel from "@/components/TMPLabel.vue";

import AllVotesModal from '@/components/modals/AllVotesModal.vue';
import ElectionProcessModal from '@/components/modals/ElectionProcessModal.vue';

type GameServerElectionV1 = definitions["Eco.WebServer.DataTransferObjects.V1.GameServerElectionV1"];
type RunoffVoteV1 = definitions["Eco.WebServer.DataTransferObjects.V1.RunoffVoteV1"];
type ElectionChoiceID = definitions["Eco.Gameplay.Civics.ElectionChoiceID"];
type RoundsForChoice = definitions["Eco.Gameplay.Civics.Elections.RoundsForChoice"];
type VoteCounts = definitions["Eco.Gameplay.Civics.Elections.VoteCounts"];

interface Candidate {
    Id: number;
    Name: string;
    Votes: number;
    Speech: string;
}

export default defineComponent({
    components: {
        WorldMap,
        draggable: VueDraggableNext,
        TMPLabel,
        AllVotesModal,
        ElectionProcessModal,
    },
    setup() {
        const store = useMainStore();

        return { store };
    },
    data() {
        return {
            election: {} as GameServerElectionV1,
            votes: [] as Array<any>,
            candidates: [] as Array<Candidate>,
            rankedCandidates: [] as Array<any>,
            test: [{ name: 'test' }],
            timeAtStart: Date.now() / 1000,
            commentText: '',
            changed: false,
            showAllVotes: false,
            showElectionProcessModal: false,
            userVotes: [] as Array<number>,
            userVotesData: [] as Array<RunoffVoteV1>,
            showGraphComposer: false,
            showWorldMap: false
        }
    },
    computed: {
        id(): string {
            return <string>this.$route.params.id;
        },
        timeEndAgo(): string {
            return sec2time(this.election.TimeEndAgo!);
        },
        timeStartAgo(): string {
            return sec2time(this.election.TimeStartAgo!);
        },
        timeToElectionEnd(): string {
            return sec2time(Math.max(0, -this.election.TimeEndAgo! - this.secondsFromStart));
        },
        secondsFromStart(): number {
            return Date.now() / 1000 - this.timeAtStart;
        },
        username(): string | null {
            return this.store.username;
        },
        rounds(): VoteCounts[] {
            const ranks = this.election.Results!.ChoiceRanks!;
            if (!ranks.length || !ranks[0].VotesPerRound) return [];
            return ranks[0].VotesPerRound;
        },
        totalTwitchVotes(): any {
            let totalVote: any = 0;
            if(this.election.Results && this.election.Results.ChoiceRanks) {
                this.election.Results.ChoiceRanks.forEach(votes => {
                    if(votes.VotesPerRound) {
                        totalVote += votes.VotesPerRound[0].TwitchVotes
                    }
                });
            }
            return Math.round(totalVote * 100) / 100;
        },
        totalUserVotes(): any {
            let totalVote: any = 0;
            if (this.election.Results && this.election.Results.ChoiceRanks) {
                this.election.Results.ChoiceRanks.forEach(votes => {
                    if (votes.VotesPerRound) {
                        totalVote += votes.VotesPerRound[0].UserVotes
                    }
                });
            }
            return Math.round(totalVote * 100) / 100;
        },
        totalAllVotes(): number | null {
            let totalVote: any = 0;
            if (this.election.Results && this.election.Results.ChoiceRanks) {
                this.election.Results.ChoiceRanks.forEach(votes => {
                    if (votes.VotesPerRound) {
                        totalVote += votes.VotesPerRound[0].Total
                    }

                });
            }
            return Math.round(totalVote * 100) / 100;
        },

    },
    watch: {
        id() {
            this.fetch();
        },
    },
    mounted() {
        this.fetch();
    },
    methods: {
        handleTMPLinkClick(event) {
            if (event.tmpLinkMethod == "click:ShowAllVotes") {
                this.showAllVotes = true;
            }
        },
        darkenHexColor(hexColor, amount = 20) {
            // Remove the '#' symbol if present
            hexColor = hexColor.replace('#', '');

            // Convert hex color to RGB format
            const r = parseInt(hexColor.substr(0, 2), 16);
            const g = parseInt(hexColor.substr(2, 2), 16);
            const b = parseInt(hexColor.substr(4, 2), 16);

            // Calculate darker RGB values
            const darkR = Math.max(r - amount, 0);
            const darkG = Math.max(g - amount, 0);
            const darkB = Math.max(b - amount, 0);

            // Convert the darker RGB values back to hex format
            const darkHex = (
                (darkR < 16 ? '0' : '') + darkR.toString(16) +
                (darkG < 16 ? '0' : '') + darkG.toString(16) +
                (darkB < 16 ? '0' : '') + darkB.toString(16)
            );

            return `#${darkHex}`;
        },
        getColorForCandidate(candidateName) {
            if (candidateName === 'Yes') {
                return '#00FF00'; // Green
            } else if (candidateName === 'No') {
                return '#FF0000'; // Red
            } else {
                // Generate a random unique color
                const randomColor = Math.floor(Math.random()*16777215).toString(16);
                return `#${randomColor}`;
            }
        },
        drawPieChartIfRequired() {
            // Check if a pie chart is required. If not, return
            if (!this.election.BooleanElection || !this.election.TotalVotes) {
                return;
            }

            // Create a dictionary to store the combined votes using the choice ranks and candidates
            const getChoices: any = this.election.Results?.ChoiceRanks;
            const combinedVotes: any = {};

            // Combine the elements based on matching IDs
            this.candidates.forEach(candidate => {
                const matchingChoice = getChoices.find(choice => choice.ChoiceID.Id === candidate.Id);

                if (matchingChoice) {
                    const color = this.getColorForCandidate(candidate.Name);
                    combinedVotes[candidate.Id] = {
                        candidate: candidate,
                        choice: matchingChoice,
                        color: color
                    };
                }
            });

            // Calculate our chart datasets for passing to Highcharts
            const percentData: any = [];
            const voteData: any = [];

            // Iterate through our combined votes and populate our various chart datasets
            for (let choiceId in combinedVotes)
            {
                const voteInfo = combinedVotes[choiceId];
                const candidateName = voteInfo.candidate.Name;
                const color = voteInfo.color;

                const choiceVotes = voteInfo.choice.VotesPerRound[0];
                const userVotes = choiceVotes.UserVotes;
                const twitchVotes = choiceVotes.TwitchVotes;
                const totalVotes = choiceVotes.Total;
                const percent = Math.round((totalVotes / this.election.TotalVotes!) * 100);

                percentData.push({
                    name: `${candidateName}: ${percent}%`,
                    y: percent,
                    color: color
                });

                voteData.push({
                    name: `User Votes`,
                    y: userVotes,
                    color: this.darkenHexColor(color, 40)
                });

                voteData.push({
                    name: `Twitch Votes`,
                    y: twitchVotes,
                    color: this.darkenHexColor(color, 40)
                });
            }

            // Render the pie chart with the computed data sets
            setTimeout(() => {
                // @ts-ignore
                Highcharts.chart('votingResultsChart', {
                    chart: {
                        type: 'pie',
                        style: { fontFamily: 'Abel' },
                        spacingBottom: 0,
                        spacingTop: 0,
                        spacingLeft: 0,
                        spacingRight: 0,
                        marginBottom: 0,
                        marginTop: 0,
                        marginLeft: 0,
                        marginRight: 0,
                        width: 500,
                        height: 500,
                        backgroundColor: 'transparent',
                    },
                    title: '',
                    credits: {
                        enabled: false
                    },
                    plotOptions: {
                        pie: {
                            shadow: false,
                            center: ['50%', '50%']
                        }
                    },
                    series: [{
                        name: 'Choice',
                        data: voteData,
                        size: '40%',
                        dataLabels: {
                            color: '#ffffff',
                            distance: -50,
                            style: {
                                fontSize: 14
                            }
                        },
                        tooltip: {
                            valueSuffix: 'Votes'
                        },
                    }, {
                        name: 'Votes',
                        data: percentData,
                        size: '50%',
                        innerSize: '75%',
                        id: 'Votes',
                        tooltip: {
                            valueSuffix: '%'
                        },
                    }],
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 400
                            },
                            chartOptions: {
                                series: [{}, {
                                    id: 'Votes',
                                    dataLabels: {
                                        enabled: false
                                    }
                                }]
                            }
                        }]
                    }
                });
            }, 500);
        },
        async fetch() {
            if (!this.id) return;

            try
            {
                this.election = (await axios.get(`/elections/${this.id}`)).data;

                let { data }: { data: Array<RunoffVoteV1> } = await axios.get(`/elections/votes?Id=${this.id}`);
                this.userVotesData = data;
                this.userVotes = [];

                // Identify our ranked votes
                data.forEach((votes, i) => {
                    if (votes.Voter == this.store.username)
                        this.userVotes = votes.RankedVotes ?? [];
                });

                const choices: Record<number, Candidate> = {};
                this.candidates = [];

                const choiceRanks: Record<number, RoundsForChoice> = {};
                this.election.Results!.ChoiceRanks!.forEach((choice, i) => {
                    choiceRanks[choice.ChoiceID!.Id!] = choice;
                });

                this.election.Choices!.forEach((choice, i) => {

                    var voteCount = choiceRanks[choice.Id!].FirstRoundVotes!;
                    var speech = decode(choice.Speech!);

                    var newChoice = { Id: choice.Id!, Name: choice.Name!, Votes: voteCount, Speech: speech };
                    choices[choice.Id!] = newChoice;

                    if (this.election.BooleanElection || !this.userVotes.includes(choice.Id!)) {
                        this.candidates.push(newChoice);
                    }
                });

                this.rankedCandidates = this.userVotes.map(id => choices[id]);
                this.drawPieChartIfRequired();
            }
            catch (ex) {
                console.log(ex);
                this.$router.replace("/elections");
            }
        },
        async vote(choiceId: number) {

            if (!this.store.username)
                return;

            let votes: Array<number> = this.election.BooleanElection ? [choiceId] : this.rankedCandidates.map(candidate => candidate.Id);

            const vote: RunoffVoteV1 = {
                Voter: this.store.username,
                ElectionID: parseInt(this.id),
                RankedVotes: votes,
            };

            try {
                await axios.post('/elections/vote', vote);

                this.$swal('Success', 'Your vote has been counted', 'success');
                this.fetch();
            } catch (e) {
                if (e instanceof AxiosError) {
                    if (e.response?.status == 401) {
                        // ideally this shouldn't happen
                        this.$swal('Oops..', "You must be logged in to do that", 'error');
                    } else {
                        this.$swal('Oops..', e.response?.data.Message, 'error');
                    }
                } else {
                    this.$swal('Oops..', 'Unknown error trying to vote', 'error');
                }
            }
        },
        async comment() {
            if (!this.store.username) {
                return this.$swal('Forbidden', 'To add comment you need to access from the game server', 'error');
            }

            if (!this.commentText.trim()) return;

            try {
                await axios.post(`/elections/addcomment?electionId=${this.id}`, {
                    Username: this.username,
                    Text: this.commentText
                });

                this.commentText = '';
                this.fetch();
            } catch (e) {
                if (e instanceof AxiosError) {
                    if (e.response?.status == 401) {
                        // ideally this shouldn't happen
                        this.$swal('Oops..', "You must be logged in to do that", 'error');
                    } else {
                        this.$swal('Oops..', e.response?.data?.Message ?? e.toJSON(), 'error');
                    }
                }
            }
        },
        sec2worldTime
    }
});

