<template>
    <div>
        <div class="webgl-error" v-if="!WebGLIsAvailable">WebGL isn't available</div>
        <div class="map-control-panel" v-if="WebGLIsAvailable">

            <!-- Map Layer -->
            <div class="map-layer-select-wrapper">
                <i class="fa fa-fw fa-caret-down"></i>
                <select class="map-layer-select" style="width: 100%">
                    <option value="">{{ $t("Select Map Layer") }}</option>
                </select>
                <a v-if="showCompare" href="/comparison" class="compare-maps-button">
                    <span class="localize">{{ $t("Compare Maps") }}</span>
                </a>
            </div>

            <!-- Map & Info -->
            <div style="line-height: 16px;position: absolute;bottom: 82px;left: 134px;border-radius: 3px;overflow: hidden;">
                <img src="/images/map-key.png" style="width: 218px;">
                <div style="position: absolute;color: white;top: -3px;padding: 8px;font-size: 14px;">Low</div>
                <div style="position: absolute;color: black;top: -3px;padding: 8px;font-size: 14px;right: 0;">High</div>
            </div>
            <div id="main-map" class="view-map">
                <p id="map-position-text"></p>
                <p id="map-info-text">.</p>
                <div type="text" class="progress-day" name="progress-day"><span class="localize">{{ $t("Day") }}</span> 0, 00:00</div>
                <input type="button" data-type="2" value="2D" id="map-toggle-2d">
            </div>

            <div class="map-controls-wrapper">

                <!-- Play Button -->
                <div class="play-button-wrapper">
                    <div id="map-toggle-play"><i class="fa fa-play" aria-hidden="true"></i></div>
                </div>

                <!-- Range Controls -->
                <div class="range-controls-wrapper">
                    <div class="map-progress-bar-wrapper">
                        <progress id="map-progress-bar" value="0.75" max="1.0"></progress>
                    </div>

                    <div class="range-controls-wrapper-2">
                        <div class="range-controls-time-wrapper">
                            <div id="range-controls-time-1">
                                <span class="localize range-control-time-span-day">{{ $t("Day") }}</span>
                                <span class="range-controls-time-span-1"> 0<br>00:00 </span>
                            </div>
                        </div>
                        <div class="multi-range-content">
                            <div id="slider-range"></div>
                        </div>
                        <div class="range-controls-time-wrapper">
                            <div id="range-controls-time-2">
                                <span class="localize range-control-time-span-day">{{ $t("Day") }}</span>
                                <span class="range-controls-time-span-2"> 0<br>00:00 </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Speed Controls -->
                <div class="speed-controls-wrapper">
                    <div id="speed-button"><span class="speed-button">0.5</span><br><span class="localize">{{ $t("days/sec") }}</span></div>
                    <input type="range" name="speed" id="map-play-speed" class="renge" min="-10" max="10" value="5" step="0.01">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import '../assets/js/MapControls';
    import { defineComponent } from 'vue'
    import MiniMap from "../assets/js/ecomap";
    import {MapControls} from "../assets/js/MapControls.js";
    export default defineComponent({
        inject: ['webgl'],
        props: {
            showCompare: {
                type: Boolean,
            }
        },
        mounted() {
            if (!this.WebGLIsAvailable) return;

            MiniMap.init();

            $(document).on('click', '#line, #column', function () {
                const isSingle = $(this).data('type');
                const selector = $(".ui-slider-handle[aria-labelledby=end-label], .graph-range-day-right, .ui-slider-input#end");

                $(".ui-slider-handle[aria-labelledby=start-label]").change();

                if (isSingle) $(".ui-slider-handle").css("pointer-events", "auto");
                isSingle ? selector.hide() : selector.show();

                $('#graph-time #start').attr({ 'min': 0, 'max': 10000 });
                $('#graph-time #end').attr({ 'min': 0, 'max': 10000 }).slider(isSingle ? 'disable' : 'enable');
                $(".ui-slider-track").css("background-color", isSingle ? '#6cb351' : 'transparent');
            });

            $(".map-layer-select").on('change', function () {
                var mapView = this.parentElement.nextElementSibling;
                MiniMap.setLayer(this.value, mapView == null || mapView.className != "view-map" ? MapControls.mainMap : mapView);
            });
        }
    });
</script>
<style>
/* Adjust the positioning of the map to fit into the parent element */
#c, .c {
    left: 295px;
    top: 67px;
}
</style>
