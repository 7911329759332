
    import { defineComponent } from "vue";
    import { useMainStore } from "../stores";

    export default defineComponent({
        setup() {
            const store = useMainStore();
            return { store };
        },
        computed: {
            pluginTypeName(): string { /* Retrieves our plugin identifier from the route slug */
                return <string>this.$route.params.plugin;
            },
            plugin(): any { /* Returns our plugin instance currently stored in session */
                return this.store.webPlugins.find(this.findWebPlugin);
            }
        },
        mounted() {
            this.store.fetchWebPlugins(); // Retrieve and store our web plugins on page load.
        },
        methods: {
            findWebPlugin(plugin) { /* Helper function to check if a plugin instance matches our plugin identifier retrieved from the route */
                return plugin.TypeName == this.pluginTypeName;
            }
        }
    });
