<template>
    <canvas ref="canvas"></canvas>
</template>
<script>
    import { defineComponent } from 'vue';
    import * as THREE from 'three';


    export default defineComponent({
        props: {
            scene: Object,
            renderer: Object,
            camera: Object
        },
        mounted() {
            const canvas = this.$refs.canvas;

            const scene = new THREE.Scene();
            const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);

            const renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: true, alpha: true });
            renderer.setClearColor(0xffffff, 0);
            renderer.setPixelRatio(window.devicePixelRatio);

            const geometry = new THREE.BoxGeometry(1, 1, 1);
            const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
            const cube = new THREE.Mesh(geometry, material);
            scene.add(cube);


            camera.position.z = 5;

            function animate() {
                requestAnimationFrame(animate);

                cube.rotation.x += 0.01;
                cube.rotation.y += 0.01;

                renderer.render(scene, camera);
            }

            animate();
        }
    });
</script>
