import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "frontpage-info-wrapper" }
const _hoisted_2 = { class: "frontpage-info" }
const _hoisted_3 = { class: "localize" }
const _hoisted_4 = { class: "text-highlight" }
const _hoisted_5 = { class: "localize" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "localize" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "localize" }
const _hoisted_10 = { class: "text-highlight" }
const _hoisted_11 = { class: "localize" }
const _hoisted_12 = { class: "text-highlight" }
const _hoisted_13 = { class: "localize" }
const _hoisted_14 = { class: "text-highlight" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", null, [
        _createElementVNode("span", _hoisted_3, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("Online Players")), 1)
        ]),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.serverInfo.OnlinePlayers) + " / " + _toDisplayString(this.serverInfo.TotalPlayers), 1)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("span", _hoisted_5, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("Time")), 1)
        ]),
        _createElementVNode("span", {
          class: "text-highlight",
          innerHTML: _ctx.time
        }, null, 8, _hoisted_6)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("span", _hoisted_7, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("Meteor Impact")), 1)
        ]),
        _createElementVNode("span", {
          class: "text-highlight",
          innerHTML: _ctx.meteorImpact
        }, null, 8, _hoisted_8)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("span", _hoisted_9, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("Plants")), 1)
        ]),
        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.serverInfo.Plants), 1)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("span", _hoisted_11, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("Laws")), 1)
        ]),
        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.serverInfo.Laws), 1)
      ]),
      _createElementVNode("li", null, [
        _createElementVNode("span", _hoisted_13, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("World Size")), 1)
        ]),
        _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.serverInfo.WorldSize), 1)
      ])
    ], 512), [
      [_vShow, _ctx.serverInfo.TimeSinceStart]
    ])
  ]))
}