
    import { defineComponent } from "vue";
    import { useMainStore } from '@/stores';
    import { sec2time, sec2worldTime } from "@/helpers/time";
    import { definitions } from "@/types/EcoWebServer"

    type ServerInfo = definitions["Eco.Shared.Networking.ServerInfo"];

    export default defineComponent({
        setup() {
            const store = useMainStore();

            return { store };
        },
        computed: {
            serverInfo(): ServerInfo {
                return this.store.serverInfo;
            },
            time(): string {
                return sec2worldTime(this.serverInfo.TimeSinceStart!);
            },
            meteorImpact(): string {
                var untilImpact = this.serverInfo.TimeLeft ?? 0;
                if (untilImpact <= 0)
                {
                    if (!this.serverInfo.HasMeteor)
                        return this.$t("No Meteor");
                    else
                        return this.$t("The meteor was destroyed");
                }
                else
                    return sec2time(untilImpact);
            },
        }
    });
