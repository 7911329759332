/**
 * Recursive method for clearing vars of any types from XSS attacks
 */
export function clearFromXSS(data: any): any {
    // Removing all <script tags
    let pattern = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;

    if (typeof data == 'string') return data.replace(pattern, '');
    if (Array.isArray(data)) return data.map(clearFromXSS);

    if (typeof data == 'object') {
        for (let key in data) {
            data[key] = clearFromXSS(data[key]);
        }
    }

    return data;
};

export function clearComments(elections) {
    for (let election of elections) {
        for (let comment of election.Comments) {
            if (!comment.Text) continue;

            let text = comment.Text.trim().replace(/\\{1,2}n/g, "<br>").replace(/\\"/g, '&quot').replace(/^["]|"$|\\/g, "");
            let matchJson = /^[\],:{}\s]*$/.test(text.replace(/\\["\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''));
            let onlyDigits = /^\d+$/.test(text);

            //checking if return text is valid json text
            if (matchJson && !onlyDigits) {
                try {
                    text = text.length ? JSON.parse(text) + '' : '';
                } catch (e) {
                    text = text + '';
                }
            } else {
                text = text + '';
            }

            while (text.indexOf("\n") >= 0) {
                text = text.replace("\n", "<br>");
            }

            comment.Text = text;
        }
    }

    return elections;
};
