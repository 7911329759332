import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import axios from 'axios';
import { setupRouter } from './router';
import { setupI18n } from './plugins/i18n'
import VueFinalModal from "vue-final-modal";
import VueAxios from 'vue-axios';
import VueSweetalert2 from 'vue-sweetalert2';
import VueProgressBar from "@aacassandra/vue3-progressbar";
import HighchartsVue from 'highcharts-vue'
import WebGLPlugin from './plugins/WebGLPlugin';

import 'sweetalert2/dist/sweetalert2.min.css';

const i18n = setupI18n();
const router = setupRouter(i18n);
const app = createApp(App);


const pinia = createPinia();
pinia.use(({ store }) => {
    store.$router = markRaw(router);
    //store.$Progress = markRaw(router);
    store.globalProperties = app.config.globalProperties;
})

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(VueFinalModal);
app.use(WebGLPlugin);
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(VueProgressBar, {
    color: '#5fa044',
    failedColor: '#872e30'
});

// cast because types are wrong https://github.com/highcharts/highcharts-vue/issues/219
//app.use(<any>HighchartsVue);

app.mount('#app');
