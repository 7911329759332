
    import { defineComponent } from 'vue';
    import { useMainStore } from '../stores';

    export default defineComponent({
        setup() {
            const store = useMainStore();

            return { store };
        },
        data() {
            return {
                table: {}
            }
        },
        computed: {
            titles() {
                return this.store.elections.titles;
            }
        }
    });
