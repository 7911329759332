export function idsToKeys(data: Array<any>): any {
    let arr = {};

	for (let item of data) {
		arr[item.Id] = item;
	}

	return arr;
};

export function decode(string: string): string {
	return string ? string.replace(/[\u00A0-\u9999<>&]/gim, i => '&#' + i.charCodeAt(0) + ';') : '';
};
