
    import { defineComponent } from "vue";
    import TMPLabel from "../components/TMPLabel.vue";
    import { useMainStore } from "../stores";

    export default defineComponent({
        setup() {
            const store = useMainStore();

            return { store };
        },
        components: {
            TMPLabel
        },
        mounted() {
            this.store.fetchElections();
        },
    });
