
<template>
    <vue-final-modal
        v-bind="$attrs"
        classes="modal-container"
        content-class="modal-content"
        :click-to-close="true"
        :esc-to-close="true">

        <!-- Content -->
        <div class="modal__content">
            <div id="election-process-modal-table-placeholder" class="fancy-election-process-contents">
                <table v-if="election.Name" class="election-info-tbl flat-table table" style="width: auto;">
                    <thead>
                    <tr class="title-heading">
                        <td class="th" colspan="3" data-toggle="tooltip" data-container="body" data-placement="left"
                            data-original-title="These are the settings which determine how this election is run.">
                            <span>{{ election.ElectionProcess.Name }}&nbsp; state: {{ election.ElectionProcess.State }}</span>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, i) in election.ElectionProcess.Table" :key="`process${i}`" class="table-middle">
                        <td class="table-left election-rule" data-toggle="tooltip" data-container="body" data-placement="left" :title="row[1]">
                            {{ row[0] }}
                        </td>
                        <td class="table-left ">{{ row[2] }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
    export default {
        name: 'ElectionProcessModal',
        inheritAttrs: false,
        props: {
            election: {
                type: Object,
                required: true,
            }
        }
    }
</script>

<style scoped>
:deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

