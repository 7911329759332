// converts seconds into days, hours, minutes
export function sec2time(seconds: number) {

    let result = '';
    let days = Math.floor(seconds / 86400);
    let hours = Math.floor((seconds - (86400 * days)) / 3600);
    let middle = (86400 * days) + (3600 * hours);
    let minutes = Math.floor((seconds - middle) / 60);

    if (days) {
        result += days + ' ' + ((days == 1) ? '<span class="time-title">day</span> ' : '<span class="time-title localize" translate-key="155">days</span> ');
    }

    if (hours) {
        result += hours + ' ' + ((hours == 1) ? '<span class="time-title">hr</span> ' : ' <span class="time-title localize" translate-key="156">hrs</span> ');
    }

    if (minutes) {
        result += minutes + ' ' + ((minutes == 1) ? '<span class="time-title">min</span> ' : '<span class="time-title localize" translate-key="157">mins</span> ');
    }

    if (!days && !hours && !minutes) {
        result = Math.floor(seconds) + ' secs';
    }

    return result;
};

// converts seconds into days, hours, minutes
export function sec2worldTime(secondsStr: string | number) {
    const seconds = parseFloat(String(secondsStr));
    const days = Math.floor(seconds / 86400);

    //getting hours
    const hours = Math.floor((seconds - (86400 * days)) / 3600);

    const hoursStr = (hours <= 9) ? '0' + hours : hours;

    const middle = (86400 * days) + (3600 * hours);

    //getting minutes
    const minutes = Math.floor((seconds - middle) / 60);
    const minutesStr = (minutes <= 9) ? '0' + minutes : minutes;

    //constructing the return text. Days + 1 so it's start with 1
    return '<span class="localize" translate-key="38">Day </span> ' + (days + 1) + ' , ' + hoursStr + ':' + minutesStr;
};
