
    import { defineComponent } from "vue";
    import { useMainStore } from '../stores';
    import TMPLabel from "./TMPLabel.vue";

    export default defineComponent({
        setup() {
            const store = useMainStore();

            return { store };
        },
        components: {
            TMPLabel
        },
        mounted() {
            this.store.fetchServerInfo();
            this.store.fetchElections();
            this.store.fetchWebPlugins();
        },
        methods: {
            highlight(path) {
                return this.$route.path == path ? 'menu-highlight' : '';
            },
            unsecuredCopyToClipboard(text) {
                const textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    document.execCommand('copy');
                } catch (err) {
                    return false;
                } finally {
                    document.body.removeChild(textArea);
                }
                return true;
            },
            async copyToClipboard() {
                let joinUrl = this.store.serverInfo.JoinUrl;
                var success = false;
                if (window.isSecureContext && navigator.clipboard) {
                    navigator.clipboard.writeText(joinUrl);
                    success = true;
                } else {
                    success = this.unsecuredCopyToClipboard(joinUrl);
                }

                if (success) {
                    this.$swal('', 'Copied!', 'success');
                } else {
                    this.$swal('', 'Your browser does not support copying this url to your clipboard.<br> Copy and paste this link instead: ' + joinUrl, 'success');
                }
            }
        }
    });
