import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f400a67"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal__content" }
const _hoisted_2 = { class: "fancy-all-votes" }
const _hoisted_3 = { class: "current-rankings" }
const _hoisted_4 = {
  key: 0,
  class: "flat-table table"
}
const _hoisted_5 = {
  class: "th",
  colspan: "5"
}
const _hoisted_6 = {
  key: 0,
  class: "localize"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  class: "table-key",
  colspan: "12"
}
const _hoisted_9 = { class: "localize" }
const _hoisted_10 = { class: "table-top table-top-left table-key" }
const _hoisted_11 = { class: "localize" }
const _hoisted_12 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_component_vue_final_modal, _mergeProps(_ctx.$attrs, {
    classes: "modal-container",
    "content-class": "modal-content",
    "click-to-close": true,
    "esc-to-close": true
  }), {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.election && _ctx.election.Name)
              ? (_openBlock(), _createElementBlock("table", _hoisted_4, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_5, [
                        (_ctx.election.BooleanElection)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t("All Votes")), 1))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (!_ctx.election.BooleanElection)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_7, [
                          _createElementVNode("td", _hoisted_8, [
                            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("Candidates Ranked")), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_10, [
                        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("Voters")), 1)
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.election.Choices, (choice, i) => {
                        return (_openBlock(), _createElementBlock("td", {
                          key: `choiceRow${i}`,
                          class: "table-top"
                        }, _toDisplayString(choice.Name), 1))
                      }), 128))
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.votes, (vote, i) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: `userVote${i}`,
                        class: "table-middle table-alternating-color-2"
                      }, [
                        _createElementVNode("td", null, _toDisplayString(vote.Voter), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.getUserVotes(vote), (choice, v) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: `userChoice${v}`,
                            class: "rank"
                          }, [
                            (choice > -1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.election.BooleanElection ? "✓" : choice), 1))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }, 16))
}