import axios from 'axios';
import { defineStore } from 'pinia';
import { clearFromXSS, clearComments } from '../helpers/security';
import { idsToKeys } from '../helpers/data';
import Swal from 'sweetalert2';
interface ElectionsState {
    all: Array<any>;
    running: Array<any>;
    past: Array<any>;
    titles: Array<any>;
}
interface ServerInfo {
    Description: string;
    Version: string;
    JoinUrl: string;
}
interface WebPluginInfo {
    TypeName: string;
    FontAwesomeIcon: string;
    PluginIndexUrl: string;
    MenuTitle: string;
}
interface State {
    username: string | null;
    worldTicket: string | null;
    elections: ElectionsState;
    serverInfo: ServerInfo;
    profiling: Array<any>;
    webPlugins: Array<WebPluginInfo>;
    electionsLoaded: boolean;
}
function isTokenValid() {
    const worldTicketData = localStorage.getItem('worldTicketData');

    if (worldTicketData) {
        const { worldTicket, expiration } = JSON.parse(worldTicketData);
        const now = new Date().getTime();
        if (now > parseInt(expiration)) {
            localStorage.removeItem('worldTicketData');
            Swal.fire({
                title: 'Session Expired',
                text: 'Your session has expired. Please close the page and re-open from the game.',
                icon: 'warning',
                confirmButtonText: 'OK'
            });
        }
    }
}
export const useMainStore = defineStore("main", {
    state(): State {
        isTokenValid(); // Check token validity on load

        const worldTicketData = localStorage.getItem('worldTicketData');
        let worldTicket = null;
        if (worldTicketData) {
            worldTicket = JSON.parse(worldTicketData).worldTicket;
        }

        return {
            username: localStorage.getItem('username'),
            worldTicket: worldTicket,
            elections: { all: [], running: [], past: [], titles: [] },
            serverInfo: { Description: 'loading...', Version: 'loading...', JoinUrl: 'loading...' },
            profiling: [],
            webPlugins: [],
            electionsLoaded: false,
        }
    },
    actions: {
        auth() {
            const params = new URLSearchParams(location.search);

            // update username & worldTicket

            var username = params.get('username');
            var worldTicket = params.get('worldTicket');
            const expiration = new Date().getTime() + 24 * 60 * 60 * 1000;

            if (username && worldTicket) {
                localStorage.setItem('username', username);
                // add worldTicket with 24 hour expiration
                
                const worldTicketData = {
                    worldTicket: worldTicket,
                    expiration: expiration // Corrected expiration value
                };
                localStorage.setItem('worldTicketData', JSON.stringify(worldTicketData));

                this.username = username;
                this.worldTicket = worldTicketData.worldTicket;

                // Update visible URL in browser to one without params.
                this.$router.replace({ path: location.pathname });
            } else {
                if (!this.worldTicket) {
                    Swal.fire({
                        title: 'You are not logged in or session has expired',
                        text: 'You will not be able to vote while logged out, To login please re-open this page from the game.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    });
                    return;
                }
            }

            axios.interceptors.request.use(config => {
                this.globalProperties.$Progress.start();
                config.headers["X-Auth-Token"] = this.worldTicket;
                return config;
            });
        },
        async fetchServerInfo(): Promise<void> {
            const { data: res } = await axios.get('/info', { baseURL: (window as any).serverUrl });
            if (!res) return;

            this.serverInfo = res;
        },
        async fetchElections(): Promise<void> {
            const { data: runningRaw } = await axios.get('/elections?returnActive=true');
            const { data: pastRaw } = await axios.get('/elections?returnActive=false');
            const { data: titlesRaw } = await axios.get('/elections/titles');

            if (!runningRaw || !pastRaw || !titlesRaw) return;

            const running = clearFromXSS(runningRaw);
            const past = clearFromXSS(pastRaw);
            const titles = clearFromXSS(titlesRaw);

            const all = idsToKeys(clearComments([...running, ...past]));

            let val = { running, past, titles, all };

            this.elections = val;
            this.electionsLoaded = true;
        },
        async fetchProfilingResults(): Promise<void> {
            let { data: res } = await axios.get('/profiling-results');
            if (!res) return;

            this.profiling = res;
        },
        async fetchWebPlugins(): Promise<void> {
            let { data: res } = await axios.get('/plugins/web');
            if (!res) return;

            this.webPlugins = res;
        }
    }
});