import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { getLocale, setI18nLanguage, loadLocaleMessages, SUPPORT_LOCALES } from '../plugins/i18n';
import { I18n } from 'vue-i18n';

import Index from "../pages/Index.vue";
import Comparison from "../pages/Comparison.vue";
import Election from "../pages/Election.vue";
import Elections from "../pages/Elections.vue";
import Officials from "../pages/Officials.vue";
import Profiling from "../pages/Profiling.vue";
import Plugin from "../pages/Plugin.vue";
import RenderTest from "../pages/RenderTest.vue";
import Debug from "../pages/Debug.vue";

export function setupRouter(i18n: I18n) {

    // setup routes
    const routes: Array<RouteRecordRaw> = [
        { path: "/", component: Index },
        { path: "/comparison", component: Comparison },
        { path: "/elections", component: Elections },
        { path: "/election/:id", component: Election },
        { path: "/elections", redirect: "/election"}, // Legacy redirect
        { path: "/officials", component: Officials },
        { path: "/profiling-results", component: Profiling },
        { path: "/plugin/:plugin", component: Plugin },
    ];

    // create router instance
    const router = createRouter({
        history: createWebHistory(),
        routes
    });

    // development only routes, due to tree shaking will get compiled out in prod
    if (process.env.NODE_ENV === 'development') {
        router.addRoute({ path: "/render-test", component: RenderTest });
        router.addRoute({ path: "/debug", component: Debug });
    }

    // navigation guards
    router.beforeEach(async () => {
        // load locale messages
        var serverLocale = getLocale(i18n);
        var newLocale = localStorage.getItem('lang');

        if (newLocale && newLocale != serverLocale && SUPPORT_LOCALES.includes(newLocale)) {
            serverLocale = newLocale;
        }

        if (!i18n.global.availableLocales.includes(serverLocale)) {
            await loadLocaleMessages(i18n, serverLocale);
        }

        // set i18n language
        setI18nLanguage(i18n, serverLocale);
    });

    return router;
};
